import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Link,
  Badge,
  Checkbox,
  Tabs,
  Tab,
  Step,
  CardMedia
} from "@mui/material";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
// import useRazorpay from "react-razorpay";
import {  useNavigate } from "react-router-dom";
import { Preview } from "@mui/icons-material";
import XLRI_LOGO from '../../images/xlri.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from "axios";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 480,
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const getDownload=(word,url)=>{
    const a = document.createElement("a");
    a.href = url
    a.download = `${word}.pdf`
    a.target="blank"
    a.click();
    // handleCloseLoader()
    // console.log(word,"pppp")
    // const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = {
    //     cropPositionTop: 0,
    //     cropPositionLeft: 0,
    //     cropWidth: 970,
    //     cropHeigth: 400
    //   };
  
    //   html2canvas(dekstopref.current).then(canvas => {
    //     let croppedCanvas = document.createElement("canvas");
    //     let croppedCanvasContext = croppedCanvas.getContext("2d");
  
    //     croppedCanvas.width = cropWidth;
    //     croppedCanvas.height = cropHeigth;
  
    //     croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);
  
       
    //   });
    };

const Offer=(props)=>{
 
//  let count=0;
  //  useEffect(()=>{ 
   
  //   if(localStorage.getItem("currentStepStatus")=="pending"){
  //     axios({
  //                       method: "post",
  //                       url: `${process.env.REACT_APP_BASE_URL}/IMI-backend/new-data-test.php`,
  //                       data: {
  //                         type: "fetch_imi_sheet_data_test",
  //                         user_id: parseInt(localStorage.getItem("user_id")),
  //                       },
  //                     }).then((res)=>{
  //                      if(res.data[0].status==200){
  //                       InsertintoSheet(res.data[0])
  //                      }})
  //   }
  //  },[])
  //  console.log(count)
    return (
        <>
      {/* desktop version */}

       <Box sx={{display: { xs: "none", lg: "flex"},justifyContent:"center",alignItems:"center",mt:7}}>
       {/* <CheckCircleIcon sx={{color:"green",fontSize:"55px"}}/> */}
              <Box sx={{background:"#fff",borderRadius:"5px",border:"1px solid #2A7BCC",px:3,pb:3,width:330}}>
              <Box sx={{textAlign:"center"}}>
        <CheckCircleIcon sx={{color:"#2A7BCC",fontSize:"80px",pt:1,pb:0.5}}/>
       </Box>
                <Typography sx={{textAlign:"center",fontWeight:"bold",fontSize:"20px",mb:0.5}}>Thank You!</Typography>
                <Typography sx={{textAlign:"center",fontWeight:"300",fontSize:"16px",mb:3}}>
                {/* Your Admissions request has been successfully submitted. You will receive  an Admission confirmation letter
within 24 working hours  */}
                Your admission request has been successfully submitted. You'll receive an admission confirmation letter from IMI within 7 working days.
                </Typography>
            <Box sx={{display:"flex",justifyContent:"center",alignItem:"center"}}>
              {/* <a
               style={{textDecoration:"none"}}
              href={`https://insaid.co//wp-content/xlriapplications/${localStorage.getItem("user_id")}.pdf`}
              download={`${localStorage.getItem("user_id")}.pdf`}
              > */}
              {/* <Button variant="conatained" size="small" sx={{color:"#fff",background:"#2A7BCC",textTransform:"none",width:150
          ,"&:hover":{background:"#2A7BCC",color:"#fff"}}}
          onClick={()=>{getDownload(localStorage.getItem("user_id"),`${process.env.REACT_APP_BASE_URL}/wp-content/imiapplications/IMI_Application_${localStorage.getItem("user_id")}.pdf`)}}
          // disabled={nxt}
          >
            Download Form
          </Button> */}
              
              <Button  href="https://www.accredian.com/my-account/" target="blank"  variant="conatained" size="small" sx={{color:"#fff",background:"#2A7BCC",textTransform:"none",textAlign:"center",width:150
          ,"&:hover":{background:"#2A7BCC",color:"#fff" }}}
          // onClick={handleNext}
          // disabled={nxt}
          >
          Go To Dashboard
          </Button>
            {/* </a> */}
            
            
          
            </Box>
                
              </Box>
            </Box>
           {/* mobile version */}
           <Box sx={{display: { xs: "flex", lg: "none"},justifyContent:"center",alignItems:"center"}}>
       {/* <CheckCircleIcon sx={{color:"green",fontSize:"55px"}}/> */}
              <Box sx={{background:"#fff",borderRadius:"5px",border:"1px solid #2A7BCC",px:3,pb:3,width:270}}>
              <Box sx={{textAlign:"center"}}>
        <CheckCircleIcon sx={{color:"#2A7BCC",fontSize:"80px",pt:1,pb:0.5}}/>
       </Box>
                <Typography sx={{textAlign:"center",fontWeight:"500",fontSize:"20px",mb:0.5}}>Thank You!</Typography>
                <Typography sx={{textAlign:"center",fontWeight:"300",fontSize:"16px",mb:3}}>
                {/* Your admission request has been successfully submitted.You'll receive an admission confirmation letter within 24 working hours. */}
                Your admission request has been successfully submitted. You'll receive an admission confirmation letter from IMI within 7 working days.  
                </Typography>
            <Box sx={{display:"flex",justifyContent:"center",alignItem:"center"}}>
              {/* <a
               style={{textDecoration:"none"}}
              href={`https://insaid.co//wp-content/xlriapplications/${localStorage.getItem("user_id")}.pdf`}
              download={`${localStorage.getItem("user_id")}.pdf`}
              > */}
              {/* <Button variant="conatained" size="small" sx={{color:"#fff",background:"#2A7BCC",textTransform:"none",width:100,height:50 
          ,"&:hover":{background:"#2A7BCC",color:"#fff"}}}
          onClick={()=>{getDownload(localStorage.getItem("user_id"),`${process.env.REACT_APP_BASE_URL}/wp-content/imiapplications/IMI_Application_${localStorage.getItem("user_id")}.pdf`)}}
          // disabled={nxt}
          >
            Download Form
          </Button> */}
              
              <Button  href="https://www.accredian.com/my-account/" target="blank"  variant="conatained" size="small" sx={{color:"#fff",background:"#2A7BCC",textTransform:"none",width:100,height:50,ml:10,textAlign:"center"
          ,"&:hover":{background:"#2A7BCC",color:"#fff"}}}
          // onClick={handleNext}
          // disabled={nxt}
          >
          Go To Dashboard
          </Button>
            {/* </a> */}
            
            
          
            </Box>
                
              </Box>
            </Box>
      
            
        </>
    )
}
export default Offer