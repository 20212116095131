import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
import { alpha, styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import icon from '../../images/icon.png'
import { Country, State, City } from "country-state-city";
import logo from '../../images/accredian-logo.png'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import EmailIcon from '@mui/icons-material/Email';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 7,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 400,
    height: 19,
    padding: "10px 12px",
    boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#2A7BCC" : "#2A7BCC",
  },
}));

const AdmissionFee = (props) => {
  let navigate = useNavigate();
  const [user, setUser] = useState("");

  const [openPersonal, setOpenPersonal] = useState(false);
  const [openADD, setOpenADD] = useState(false);
  const [openaddress, setOpenaddress] = useState(false);

  const [course, setCourse] = useState([]);
  const [courses, setCourses] = useState(" ");
  const [coursescode, setCoursescode] = useState(" ");
  const [batch, setBatch] = useState([]);
  const [batchs, setBatchs] = useState(" ");
  const [paymentid, setPaymentid] = useState("");
  const [backopen, setBackopen] = useState(false);
  const [addmissionPayment, setAddmissionpayment] = useState();
  const [batch_name, setBatchname] = useState('');
  const [program_name, setProgramname] = useState('');
  const [batchid, setBatchid] = useState('');
  const [programid, setProgramid] = useState('');
  const [enrol_id, setenrol] = useState('');
  const [customer_id, setCustmerid] = useState('');
  // const [batch, setBatch] = useState([]);
  // const [course, setCourse] = useState([]);

  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [pincode, setPincode] = useState();
  const [address, setaddress] = useState();
  const [addresstwo, setAddresstwo] = useState("");
  const [country_name, setCountryName] = useState();
  const [cities, setCities] = useState([]);
  const [stateCode, setStatecode] = useState();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [companyemail, setCompanyemail] = useState();
  const[checked,setChecked]=useState("")
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    setState('');
    setCity('')
  };
  const handleChangeState = (event) => {
    setState(event.target.value);
    setCity('')
  };
  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(country, stateCode);
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));
        // console.log(allCities,"rrrr")
        setCities(allCities);
      } catch (error) {
        setCities([]);
      }
    };

    getCities();
  }, [state, stateCode, country]);
  // console.log(gender,"uuuu")
  useEffect(() => {
    if (country) {
      const country_name = Country.getAllCountries().filter((val) => {
        return val.isoCode == country;
      });
      // console.log(country_name[0].name)
      setCountryName(country_name[0].name);
    }
  }, [country]);
  useEffect(() => {
    if (state) {
      const states = State.getAllStates().filter((val) => {
        return val.name == state;
      });
      // console.log(states[0].isoCode)
      setStatecode(states[0].isoCode);
    }
  }, [state]);
  useEffect(() => {
    var user_id = localStorage.getItem("user_id");
    setUser(user_id);
  }, []);

  if (user == null) {
    navigate(`/Login`);
  }
  const paymentChange = (e) => {
    setAddmissionpayment(e.target.value);
  };
  const LoaderClose = () => {
    setBackopen(false);
  };
  const LoaderOpen = () => {
    setBackopen(true);
  };
  const handleClickPersonal = () => {
    setOpenPersonal(!openPersonal);
  };
  const handleClickAdd = () => {
    setOpenADD(!openADD);
  };
  const handleClickaddress = () => {
    setOpenaddress(!openaddress);
  };
  const handleChangecourse = (pcode, pid) => {
    setCourses(pid);
    setCoursescode(pcode);
    console.log(pid);
  };
  const handleChangebatch = (event) => {
    setBatchs(event.target.value);
    console.log(event.target.value);
  };
  const handleChangePincode = (event) => {
    setPincode(event.target.value);
  };
  const handleChangeAddress = (event) => {
    setaddress(event.target.value);
  };
  const handleChangeAddresst = (event) => {
    setAddresstwo(event.target.value);
  };

  const[enablePay,setPay]=useState(true)
  
  const[enablePayFinance,setPayFinance]=useState(true)

  useEffect(()=>{
    if(addmissionPayment){
      setPay(false)
    }
    else{
      setPay(true)
    }
  },[enablePay,addmissionPayment])

  useEffect(()=>{
    if(country_name&&state&&city&&pincode&&address){
      setPayFinance(false)
    }
    else{
      setPayFinance(true)
    }
  },[enablePayFinance,country_name,state,city,pincode,address])
  // function userd() {
  //   axios({
  //     method: "post",
  //     url: `${process.env.REACT_APP_BASE_URL}/IMI-backend/data.php`,
  //     data: {
  //       type: "get_user_basic_details",
  //       user_id: localStorage.getItem("user_id"),
  //     },
  //   }).then(function (response) {
  //     localStorage.setItem("firstname", response.data.firstname);
  //     if (response.data.lastname.split(" ").length > 1) {
  //       localStorage.setItem("lastname", response.data.lastname.split("  ")[1]);
  //       localStorage.setItem(
  //         "middlename",
  //         response.data.lastname.split("  ")[0]
  //       );
  //     } else {
  //       localStorage.setItem("lastname", response.data.lastname);
  //     }
  //     localStorage.setItem("mobile", response.data.mobile);
  //   });
  // }
  // useEffect(() => {
  //   userd();
  //   axios({
  //     method: "post",
  //     url: `${process.env.REACT_APP_BASE_URL}/IMI-backend/data.php`,
  //     data: {
  //       type: "enrolment_details",
  //       user_id: parseInt(localStorage.getItem("user_id")),
  //       category:localStorage.getItem("category")
       
  //     },
  //   }).then((res)=>{
  //     console.log(res,"llll")
  //     setBatchname(res.data[0].batch)
  //     setBatchid(res.data[0].batch_id)
  //     setProgramname(res.data[0].program_name)
  //     setProgramid(res.data[0].program_id)
  //     setenrol(res.data[0].enrol_id)
  //     setCoursescode(res.data[0].pcode)
  //     setCustmerid(res.data[0].customer_id)
  //   })
  // }, []);
  useEffect(() => {
    if (paymentid) {
      LoaderOpen();
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/IMI-backend/new-data.php`,
        data: {
          type: "insert_admissions_details_pubsub",
          payment_id: paymentid,
          firstname: localStorage.getItem("firstname"),
          lastname: localStorage.getItem("lastname"),
          mobile: localStorage.getItem("mobile"),
          email: localStorage.getItem("email"),
          user_id: parseInt(localStorage.getItem("user_id")),
          batch: batch[0].batch_id,
          program: course[0].id,
          amount: addmissionPayment * 100,
          // country: country_name,
          // state: state,
          // city: city,
          // pincode: parseInt(pincode),
          // address: address,
          // addresstwo: addresstwo,
        },
      }).then((response) => {
        if (response.data.status == 200) {
          LoaderClose();
          props.handleNext();
          localStorage.setItem("currentStep", response.data.data.current_step_count);
          localStorage.setItem("currentStepStatus", response.data.data.current_step_status);
          
          
        } 
       
      });
    }
  }, [paymentid]);

  const fetchone=()=>{
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/IMI-backend/new-data.php`,
      data: {
        type: "fetch_imi_sm_programs_and_batches",
      },
    }).then((response) => {
      // console.log(response.data[0].pname,"courseee")
      if(response.data.status==200){
        if(response.data.batches.status==200){
          // setBatch(response.data);
          setBatch([
            {
              id: response.data.batches.batch,
              name: response.data.batches.batch_text,
              batch_id: response.data.batches.batch_id,
            },
          ]);
          // setBatchid(response.data.batches.batch_id)
          // setBatchtxt(response.data.batches.batch_text);
        }
        if(response.data.programs.status==200){
          // setCourse(response.data);
          setCourse([
            {
              id: response.data.programs.pid,
              name: response.data.programs.pname,
              pcode: response.data.programs.pcode,
            },
          ]);
          // setCoursescode(response.data.programs.pcode);
          // setCourses(response.data.programs.pid);
        }
      }
      
    });
  }
  useEffect(() => {
    fetchone()
    // props.userd();
  }, []);

  // console.log(course[0].pcode,"check")
  const checkoutHandler = async (amount) => {
    const options = {
      key: "rzp_live_kuHNpOKPMVihkI",
      // key:"rzp_test_KAHiAuZT84j7ih",
      amount: addmissionPayment * 100,
      currency: "INR",
      name: "Accredian",
      description: "admission fees for " + course[0].pcode,
      image:{logo},
      // order_id: order.data.response.id,
      handler: function (response) {
        setPaymentid(response.razorpay_payment_id);
      },
      prefill: {
        name:
          localStorage.getItem("firstname") + localStorage.getItem("lastname"),
        email: localStorage.getItem("email"),
        contact: localStorage.getItem("mobile"),
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };
  const handleCheck=( event)=>{
    setChecked(event.target.checked);
  }

  const handleChangeCompanyemail = (event) => {
    setCompanyemail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(event.target.value));
  };
  const [payment, setPayment] = useState("");
  const [show, setShow] = useState(false);
  const handlePayment = () => {
    setAddmissionpayment('')
   
    document.getElementById("one").style.background = "#2A7BCC";
    document.getElementById("onet").style.color = "#fff";
    // document.getElementById("debit").style.background = "#fff";
    // document.getElementById("debitt").style.color = "#000";
    document.getElementById("credit").style.background = "#fff";
    document.getElementById("creditt").style.color = "#000";
    document.getElementById("finance").style.background = "#fff";
    document.getElementById("financet").style.color = "#000";
    setPayment("oneTime");
  };
  const handlePaymentDebit = () => {
    // document.getElementById("debit").style.background = "#2A7BCC";
    // document.getElementById("debitt").style.color = "#fff";
    document.getElementById("one").style.background = "#fff";
    document.getElementById("onet").style.color = "#000";
    document.getElementById("credit").style.background = "#fff";
    document.getElementById("creditt").style.color = "#000";
    document.getElementById("finance").style.background = "#fff";
    document.getElementById("financet").style.color = "#000";
    setPayment("debit");
  };
  const handlePaymentCredit = () => {
    setAddmissionpayment('')
    // setCountry('')
    // setState('')
    // setCity('')
    document.getElementById("credit").style.background = "#2A7BCC";
    document.getElementById("creditt").style.color = "#fff";
    // document.getElementById("debit").style.background = "#fff";
    // document.getElementById("debitt").style.color = "#000";
    document.getElementById("one").style.background = "#fff";
    document.getElementById("onet").style.color = "#000";
    document.getElementById("finance").style.background = "#fff";
    document.getElementById("financet").style.color = "#000";
    setPayment("credit");
  };
  const handlePaymentFinance = () => {
    // setCity('')
    document.getElementById("finance").style.background = "#2A7BCC";
    document.getElementById("financet").style.color = "#fff";
    document.getElementById("credit").style.background = "#fff";
    document.getElementById("creditt").style.color = "#000";
    // document.getElementById("debit").style.background = "#fff";
    // document.getElementById("debitt").style.color = "#000";
    document.getElementById("one").style.background = "#fff";
    document.getElementById("onet").style.color = "#000";
    setPayment("finance");
  };
  const handleClick = () => {
    LoaderOpen()
    
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/IMI-backend/new-data.php`,
      data: {
        type: "update_vendor_admission_payment",
        user_id: parseInt(localStorage.getItem("user_id")),
        
      },
    }).then((res)=>{
      LoaderClose()
      if(res.data.status==200){
      
       localStorage.setItem("currentStep", res.data.data.current_step_count);
       localStorage.setItem("currentStepStatus", res.data.data.current_step_status);
       toast.success(
        "Thanks for submitting your request. We will get back to you for further process",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      setTimeout(() => {
        setShow(true);
      }, 4000);
      }
    })
    //   }
      
    // });
    
  };
  const handleClickChange = () => {
    LoaderOpen()
    // axios({
    //   method: "post",
    //   url: `${process.env.REACT_APP_BASE_URL}/IMI-backend/data.php`,
    //   data: {
    //     type: "update_step_three_status",
    //     user_id: parseInt(localStorage.getItem("user_id")),
    //     status: "pending",
    //   },
    // }).then((response) => {
    //   if (response.data.status == 200) {
    //     LoaderClose()
    //     props.stepCount();
    //     setShow(false);
       
    //     console.log("inprocess");
    //   }
    // });


    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/IMI-backend/new-data.php`,
      data: {
        type: "update_choice_admission_payment",
        user_id: parseInt(localStorage.getItem("user_id")),
       
      },
    }).then((res)=>{
      if(res.data.status==200){
        // setpaymentChange(true)
        LoaderClose()
        setShow(false)
        setChecked(!checked)
        localStorage.setItem("currentStep", res.data.data.current_step_count);
        localStorage.setItem("currentStepStatus", res.data.data.current_step_status);
      }
    })
    
   
   
  };
  // console.log(show,"show");
  // console.log("ffffff")
  return (
    <>
    <Box sx={{display:{xs:"none",lg:"block"}}}>
    <Box
        sx={{
          display: "grid",
          placeItems: "center",
          p: 3,

          width: "95%",
          borderRadius: "15px",

          overflowY: "scroll",
          height: 350,
          "&::-webkit-scrollbar": {
            width: "0.2em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px silver",
            webkitBoxShadow: "inset 0 0 6px silver",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "silver",
            outline: "1px solid silver",
          },
        }}
      >
                 
            <Box
            
              sx={{
                borderRadius: "8px",
                p: 1.5,
              
                background: "#fff",
                backgroundColor:!show ?"rgba(255, 255, 255, .60)":"transparent" ,
                // pl: 4,
                // pr: 4,
             
                ml:5,
                backdropFilter:!show ?"blur(5px)":""  ,
                // display: "grid",
                placeContent: "center",
                border: !show ?"1px solid #002E5C":"",
               width:"82%",
              }}
            >
        {!show &&  localStorage.getItem("currentStepStatus") == "pending" ? (

             
              <Box sx={{ p: 1.5}}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "23px",
                    }}
                  >
                    Select Your Payment Method
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",

                    mt: 1,
                    mb: 3,
                  }}
                >
                  <Box
                    id="one"
                    sx={{
                      p: 2,
                      border: "1px solid #2A7BCC",
                      borderRadius: "7px",
                      background: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={handlePayment}
                  >
                    <Typography
                      id="onet"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      One time payment
                    </Typography>
                    {/* <BootstrapInput
                sx={{ width: 480 }}
                size="small"
                inputProps={{ readOnly: true }}
                value={localStorage.getItem("firstname")}
              /> */}
                  </Box>
                  <Box
                    id="credit"
                    sx={{
                      p: 2,
                      border: "1px solid #2A7BCC",
                      borderRadius: "5px",
                      background: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={handlePaymentCredit}
                  >
                    <Typography
                      id="creditt"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Credit Card EMI
                    </Typography>
                    {/* <BootstrapInput
                sx={{ width: 480 }}
                size="small"
                inputProps={{ readOnly: true }}
                value={localStorage.getItem("middlename")}
              /> */}
                  </Box>
                  {/* <Box
                    id="debit"
                    sx={{
                      p: 2,
                      border: "1px solid #2A7BCC",
                      borderRadius: "5px",
                      background: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={handlePaymentDebit}
                  >
                    <Typography
                      id="debitt"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Debit Card EMI
                    </Typography>
             
                  </Box> */}
                  <Box
                    id="finance"
                    sx={{
                      p: 2,
                      border: "1px solid #2A7BCC",
                      borderRadius: "5px",
                      background: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={handlePaymentFinance}
                  >
                    <Typography
                      id="financet"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Finance partner
                    </Typography>
                    {/* <BootstrapInput
                sx={{ width: 480 }}
                size="small"
                inputProps={{ readOnly: true }}
                value={localStorage.getItem("firstname")}
              /> */}
                  </Box>
                </Box>
                {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 1,
              mb: 1,
            }}
          >
           
           
            
          </Box> */}
                {payment == "" ? (
                  <Box></Box>
                ) : (
                  <Box>
                    {!(payment == "finance") ? (
                      <Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 1,
                            mb: 1,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                              }}
                            >
                              Email ID <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              type="email"
                              sx={{ width: 425,borderRadius:"5px",background:"#d9d9d9" }}
                              size="small"
                              inputProps={{ readOnly: true }}
                              value={localStorage.getItem("email")}
                            />

                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                              }}
                            >
                            Program <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              // type="email"
                              sx={{ width: 425,borderRadius:"5px",background:"#d9d9d9" }}
                              size="small"
                              inputProps={{ readOnly: true }}
                              value={course[0].name}
                            />
                            {/* <FormControl fullWidth>
                              <Select
                                size="small"
                                sx={{
                                  width: 428,
                                  background: "#fff",
                                  textAlign: "left",
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={country}
                                defaultValue="38"
                                label="course"
                                // onChange={handleChangeCountry}
                              >
                                {course.map((val) => (
                                  <MenuItem
                                    key={val.pid}
                                    value={val.pid}
                                    onClick={() =>
                                      handleChangecourse(val.pcode, val.pid)
                                    }
                                  >
                                    {val.pname}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 1,
                            mb: 1,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                              }}
                            >
                              Admission Fee <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <BootstrapInput
                            type="number"
                              sx={{ width: 480 }}
                              size="small"
                              // inputProps={{ readOnly: true }}
                              onChange={paymentChange}
                            />
                          </Box>
                          <Box sx={{ mb: 1 }}>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                              }}
                            >
                              Batch <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              // type="email"
                              sx={{ width: 425,borderRadius:"5px",background:"#d9d9d9" }}
                              size="small"
                              inputProps={{ readOnly: true }}
                              value={batch[0].name}
                            />
                            {/* <FormControl fullWidth>
                             
                              <Select
                                size="small"
                                sx={{
                                  width: 428,
                                  background: "#fff",
                                  textAlign: "left",
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={batchs}
                                // defaultValue="2023-06-01"
                                // label="batch"
                                onChange={handleChangebatch}
                              >
                                {batch.map((val, i) => (
                                  <MenuItem value={val.batch_id}>
                                    {val.batch_text}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
                          </Box>

                         



                        </Box>



                        
                        {/* <Box
              sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
              // onClick={handleClickaddress}
            >
             
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "23px",
                }}
              >
                Address Details
              </Typography>
            </Box>
          
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Country <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                  
                  <Select
                    size="small"
                    sx={{ width: 427, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    // defaultValue={countryCode}
                    label="Gender"
                    onChange={handleChangeCountry}
                    
                  >
                    {Country.getAllCountries().map((val, i) => (
                      <MenuItem value={val.isoCode}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  State <span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                  
                  <Select
                    size="small"
                    sx={{ width: 427, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state}
                    label="Gender"
                    onChange={handleChangeState}
                    // renderValue={(selected) => selected.map(obj=> State[obj - 1].value).join(", ")}
                  >
                    {State.getAllStates()
                      .filter((val) => {
                        return val.countryCode == country;
                      })
                      .sort()
                      .map((val) => (
                        <MenuItem value={val.name}>{val.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  City <span style={{ color: "red" }}>*</span>
                </Typography>

                <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                  
                  <Select
                    size="small"
                    sx={{ width: 427, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={city}
                    label="Gender"
                    onChange={handleChangeCity}
                  >
                    {cities.map((val) => (
                      <MenuItem value={val.name}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Pincode <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangePincode}
                  value={pincode}
                  type="number"
                  // sx={{ width: 480 }}
                  size="small"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 1<span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangeAddress}
                  value={address}
                  sx={{ width: 480 }}
                  size="small"
                  type="text"
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 2{" "}
                </Typography>
                <BootstrapInput
                  onChange={handleChangeAddresst}
                  value={addresstwo}
                  // sx={{ width: 480 }}
                  size="small"
                  type="text"
                />
              </Box>
            </Box> */}


                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                         

                          <Button
                            // variant="conatained"
                            // size="small"
                            disabled={enablePay}
                            sx={{
                              color: "#fff",
                              mt: 1,
                              textTransform:"none",
                              background: "#002E5C",
                              "&:hover": {
                                background: "#002E5C",
                                color: "#fff",
                              },
                            }}
                            onClick={() => {
                              checkoutHandler(addmissionPayment);
                            }}
                            variant="contained"
              size="small"
              width="150"
              height="80"
                          >
                            Pay Now
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Box>

<Box sx={{display: { xs: "none", lg: "flex"},justifyContent:"center",alignItems:"center",mt:4,mb:2}}>
     
     <Box sx={{background:"#fff",borderRadius:"8px",border:"1px solid #2A7BCC",px:1,pb:1.5,width:450}}>
     <Box sx={{textAlign:"center",my:1,}}>
     <img src={icon} alt="logo"  /> 
</Box>
      
       <Typography sx={{fontWeight:"300",fontSize:"16px",mb:1,textAlign:"left",p:1}}>
       Thank you for choosing Finance partner as your 
payment option for the program. Please note that 
Finance partner is a third-party payment service 
provider and their terms and conditions will apply 
to your transaction.
       
       </Typography>

       {/* <Typography sx={{fontSize:"10px",textAlign:"left",p:1}}>
       I agree to the terms and conditions of the finance partner and provide required documents for loan approval.
       </Typography> */}
       <FormGroup>
      {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
      <FormControlLabel  control={<Checkbox sx={{fontSize:"12px"}} checked={checked} onChange={handleCheck} />} label={
         <div style={{fontSize:"12px",textAlign:"left"}}>
       
      <span style={{color:"red",fontSize:"13px"}}>*</span> I agree to the terms and conditions of the finance partner and provide required documents for loan approval.
       </div>
      } />
      {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
    </FormGroup>
 
       
     </Box>



     
   </Box>


                         {/* <Box
              sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
             
            >
             
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "23px",
                }}
              >
                Address Details
              </Typography>
            </Box>
           
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Country <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                 
                  <Select
                    size="small"
                    sx={{ width: 427, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    
                    label="Gender"
                    onChange={handleChangeCountry}
                    
                  >
                    {Country.getAllCountries().map((val, i) => (
                      <MenuItem value={val.isoCode}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  State <span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                 
                  <Select
                    size="small"
                    sx={{ width: 427, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state}
                    label="Gender"
                    onChange={handleChangeState}
                   
                  >
                    {State.getAllStates()
                      .filter((val) => {
                        return val.countryCode == country;
                      })
                      .sort()
                      .map((val) => (
                        <MenuItem value={val.name}>{val.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  City <span style={{ color: "red" }}>*</span>
                </Typography>

                <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                 
                  <Select
                    size="small"
                    sx={{ width: 427, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={city}
                    label="Gender"
                    onChange={handleChangeCity}
                  >
                    {cities.map((val) => (
                      <MenuItem value={val.name}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Pincode <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangePincode}
                  value={pincode}
                  type="number"
                
                  size="small"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 1<span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangeAddress}
                  value={address}
                  sx={{ width: 480 }}
                  size="small"
                  type="text"
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 2{" "}
                </Typography>
                <BootstrapInput
                  onChange={handleChangeAddresst}
                  value={addresstwo}
                 
                  size="small"
                  type="text"
                />
              </Box>
            </Box> */}

                              
            <Box sx={{display:"flex",justifyContent:"flex-end",mt:2}}>
      
              
      <Button
                    onClick={handleClick}
                    // variant="conatained"
                    // size="small"
                    disabled={!checked}
                    sx={{
                      color: "#fff",
                      textTransform:"none",
                      // mt: 1,
                      background: "#002E5C",
                      "&:hover": {
                        background: "#002E5C",
                        color: "#fff",
                      },
                    }}
                    variant="contained"
              size="small"
              width="150"
              height="80"
                  >
                    Next
                  </Button>
    {/* </a> */}
    
    
  
    </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
       
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background: "#fff",
                borderRadius: "5px",
                border: "1px solid #2A7BCC",
                p: 3,
                width: 300,
              }}
            >
              <BorderLinearProgress
                variant="determinate"
                value={80}
                sx={{ mb: 2 }}
              />
              <Typography sx={{ mb: 1.5 }}>
                Your payment is under process. Please contact your admission
                counsellor for further details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={handleClickChange}
                  variant="conatained"
                  size="small"
                  sx={{
                    textAlign: "center",
                    color: "#fff",

                    textTransform: "none",
                    background: "#2A7BCC",
                    "&:hover": { background: "#2A7BCC", color: "#fff" },
                  }}
                  // onClick={handleChangePayment}
                >
                  Choose Another Payment Method
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
    </Box>
         

    {/* mobile version */}
     
    <Box sx={{display:{xs:"block",lg:"none"}}}>
    <Box
        sx={{
          display: "grid",
          placeItems: "center",
          p: 3,

          width: "85%",
          borderRadius: "15px",
          overflowX:"hidden",
          overflowY: "scroll",
          height: 400,
          "&::-webkit-scrollbar": {
            width: "0.2em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px silver",
            webkitBoxShadow: "inset 0 0 6px silver",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "silver",
            outline: "1px solid silver",
          },
        }}
      >
                 
            <Box
            
              sx={{
                borderRadius: "8px",
                p: 1.5,
                background: "#fff",
                backgroundColor:!show ?"rgba(255, 255, 255, .60)":"transparent" ,
                // pl: 4,
                // pr: 4,
             
                
                backdropFilter:!show ?"blur(5px)":""  ,
                // display: "grid",
                placeContent: "center",
                border: !show ?"1px solid #002E5C":"",
               width:275,
              }}
            >
        {!show &&  localStorage.getItem("currentStepStatus") == "pending" ? (

             
              <Box sx={{ p: 1.5}}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "23px",
                    }}
                  >
                    Select Your Payment Method
                  </Typography>
                </Box>

                <Box
                  sx={{
                    // display: "flex",
                    // justifyContent: "space-between",

                    mt: 1,
                    mb: 3,
                  }}
                >
                  <Box
                    id="one"
                    sx={{
                      p: 2,
                      border: "1px solid #2A7BCC",
                      borderRadius: "7px",
                      background: "#fff",
                      cursor: "pointer",
                      mb:1
                    }}
                    onClick={handlePayment}
                  >
                    <Typography
                      id="onet"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      One time payment
                    </Typography>
                    {/* <BootstrapInput
                sx={{ width: 480 }}
                size="small"
                inputProps={{ readOnly: true }}
                value={localStorage.getItem("firstname")}
              /> */}
                  </Box>
                  <Box
                    id="credit"
                    sx={{
                      p: 2,
                      border: "1px solid #2A7BCC",
                      borderRadius: "5px",
                      background: "#fff",
                      cursor: "pointer",
                      mb:1
                    }}
                    onClick={handlePaymentCredit}
                  >
                    <Typography
                      id="creditt"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Credit Card EMI
                    </Typography>
                    {/* <BootstrapInput
                sx={{ width: 480 }}
                size="small"
                inputProps={{ readOnly: true }}
                value={localStorage.getItem("middlename")}
              /> */}
                  </Box>
                  {/* <Box
                    id="debit"
                    sx={{
                      p: 2,
                      border: "1px solid #2A7BCC",
                      borderRadius: "5px",
                      background: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={handlePaymentDebit}
                  >
                    <Typography
                      id="debitt"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Debit Card EMI
                    </Typography>
             
                  </Box> */}
                  <Box
                    id="finance"
                    sx={{
                      p: 2,
                      border: "1px solid #2A7BCC",
                      borderRadius: "5px",
                      background: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={handlePaymentFinance}
                  >
                    <Typography
                      id="financet"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Finance partner
                    </Typography>
                    {/* <BootstrapInput
                sx={{ width: 480 }}
                size="small"
                inputProps={{ readOnly: true }}
                value={localStorage.getItem("firstname")}
              /> */}
                  </Box>
                </Box>
                {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 1,
              mb: 1,
            }}
          >
           
           
            
          </Box> */}
                {payment == "" ? (
                  <Box></Box>
                ) : (
                  <Box>
                    {!(payment == "finance") ? (
                      <Box>

                        <Box
                          sx={{
                            // display: "flex",
                            // justifyContent: "space-between",
                            mt: 1,
                            mb: 1,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                              }}
                            >
                              Email ID <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              type="email"
                              sx={{ width: 250,borderRadius:"5px",background:"#d9d9d9" }}
                              size="small"
                              inputProps={{ readOnly: true }}
                              value={localStorage.getItem("email")}
                            />
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                              }}
                            >
                            Program <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              // type="email"
                              sx={{ width: 250,borderRadius:"5px",background:"#d9d9d9" }}
                              size="small"
                              inputProps={{ readOnly: true }}
                              value={course[0].name}
                            />
                            {/* <FormControl fullWidth>
                              <Select
                                size="small"
                                sx={{
                                  width: 428,
                                  background: "#fff",
                                  textAlign: "left",
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={country}
                                defaultValue="38"
                                label="course"
                                // onChange={handleChangeCountry}
                              >
                                {course.map((val) => (
                                  <MenuItem
                                    key={val.pid}
                                    value={val.pid}
                                    onClick={() =>
                                      handleChangecourse(val.pcode, val.pid)
                                    }
                                  >
                                    {val.pname}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            // display: "flex",
                            // justifyContent: "space-between",
                            mt: 1,
                            mb: 1,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                              }}
                            >
                              Admission Fee <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <BootstrapInput
                             type="number"
                              sx={{ width: 250 }}
                              size="small"
                              // inputProps={{ readOnly: true }}
                              onChange={paymentChange}
                            />
                          </Box>
                          <Box sx={{ mb: 1 }}>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                              }}
                            >
                              Batch <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              // type="email"
                              sx={{ width: 250,borderRadius:"5px",background:"#d9d9d9" }}
                              size="small"
                              inputProps={{ readOnly: true }}
                              value={batch[0].name}
                            />
                            {/* <FormControl fullWidth>
                             
                              <Select
                                size="small"
                                sx={{
                                  width: 428,
                                  background: "#fff",
                                  textAlign: "left",
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={batchs}
                                // defaultValue="2023-06-01"
                                // label="batch"
                                onChange={handleChangebatch}
                              >
                                {batch.map((val, i) => (
                                  <MenuItem value={val.batch_id}>
                                    {val.batch_text}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
                          </Box>
                        </Box>

                        {/* <Box
              sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
              // onClick={handleClickaddress}
            >
             
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "23px",
                }}
              >
                Address Details
              </Typography>
            </Box>
            
            <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Country <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <FormControl fullWidth>
                  
                  <Select
                    size="small"
                    sx={{ width: 250, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label="Gender"
                    onChange={handleChangeCountry}
                  >
                    {Country.getAllCountries().map((val, i) => (
                      <MenuItem value={val.isoCode}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  State <span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl fullWidth>
                  
                  <Select
                    size="small"
                    sx={{ width: 250, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state}
                    label="Gender"
                    onChange={handleChangeState}
                  >
                    {State.getAllStates()
                      .filter((val) => {
                        return val.countryCode == country;
                      })
                      .sort()
                      .map((val) => (
                        <MenuItem value={val.name}>{val.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  City <span style={{ color: "red" }}>*</span>
                </Typography>

                <FormControl fullWidth>
                 
                  <Select
                    size="small"
                    sx={{ width: 250, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={city}
                    label="Gender"
                    onChange={handleChangeCity}
                  >
                    {cities.map((val) => (
                      <MenuItem value={val.name}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Pincode <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangePincode}
                  value={pincode}
                  type="number"
                  sx={{ width: 250 }}
                  size="small"
                />
              </Box>
            </Box>
            <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 1 <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangeAddress}
                  value={address}
                  sx={{ width: 250 }}
                  size="small"
                  type="text"
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 2{" "}
                </Typography>
                <BootstrapInput
                  onChange={handleChangeAddresst}
                  value={addresstwo}
                  sx={{ width: 250 }}
                  size="small"
                  type="text"
                />
              </Box>
            </Box> */}




                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                         

                          <Button
                            // variant="conatained"
                            // size="small"
                            disabled={enablePay}
                            sx={{
                              color: "#fff",
                              mt: 1,
                              textTransform:"none",
                              background: "#002E5C",
                              "&:hover": {
                                background: "#002E5C",
                                color: "#fff",
                              },
                            }}
                            onClick={() => {
                              checkoutHandler(addmissionPayment);
                            }}
                            variant="contained"
              size="small"
              width="150"
              height="80"
                          >
                            Pay Now
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                              <Box sx={{display: { xs: "flex", lg: "none"},justifyContent:"center",alignItems:"center",mt:4}}>
     
     <Box sx={{background:"#fff",borderRadius:"8px",border:"1px solid #2A7BCC",px:1,pb:1.5,width:350}}>
     <Box sx={{textAlign:"center",my:1,}}>
     <img src={icon} alt="logo"  /> 
</Box>
      
       <Typography sx={{fontWeight:"300",fontSize:"16px",mb:2.5,textAlign:"center",p:1}}>
       Thank you for choosing Finance partner as your 
payment option for the program. Please note that 
Finance partner is a third-party payment service 
provider and their terms and conditions will apply 
to your transaction.
       
       </Typography>
       <FormGroup>
      {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
      <FormControlLabel  control={<Checkbox sx={{fontSize:"12px"}} onChange={handleCheck} />} label={
         <div style={{fontSize:"10px",padding:"5px",textAlign:"left"}}>
       
      <span style={{color:"red"}}>*</span> I agree to the terms and conditions of the finance partner and provide required documents for loan approval.
       </div>
      } />
      {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
    </FormGroup>
   
       
     </Box>
   </Box>


   {/* <Box
              sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
              // onClick={handleClickaddress}
            >
              
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "23px",
                }}
              >
                Address Details
              </Typography>
            </Box> */}
            
            {/* <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Country <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <FormControl fullWidth>
                 
                  <Select
                    size="small"
                    sx={{ width: 250, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label="Gender"
                    onChange={handleChangeCountry}
                  >
                    {Country.getAllCountries().map((val, i) => (
                      <MenuItem value={val.isoCode}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  State <span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl fullWidth>
                 
                  <Select
                    size="small"
                    sx={{ width: 250, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state}
                    label="Gender"
                    onChange={handleChangeState}
                  >
                    {State.getAllStates()
                      .filter((val) => {
                        return val.countryCode == country;
                      })
                      .sort()
                      .map((val) => (
                        <MenuItem value={val.name}>{val.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box> */}
            {/* <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  City <span style={{ color: "red" }}>*</span>
                </Typography>

                <FormControl fullWidth>
                  
                  <Select
                    size="small"
                    sx={{ width: 250, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={city}
                    label="Gender"
                    onChange={handleChangeCity}
                  >
                    {cities.map((val) => (
                      <MenuItem value={val.name}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Pincode <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangePincode}
                  value={pincode}
                  type="number"
                  sx={{ width: 250 }}
                  size="small"
                />
              </Box>
            </Box> */}
            {/* <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 1 <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangeAddress}
                  value={address}
                  sx={{ width: 250 }}
                  size="small"
                  type="text"
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 2{" "}
                </Typography>
                <BootstrapInput
                  onChange={handleChangeAddresst}
                  value={addresstwo}
                  sx={{ width: 250 }}
                  size="small"
                  type="text"
                />
              </Box>
            </Box> */}

            <Box sx={{display:"flex",justifyContent:"flex-end",mt:2}}>

     
<Button
              onClick={handleClick}
              // variant="conatained"
              // size="small"
              disabled={!checked}
              sx={{
                color: "#fff",
                textTransform:"none",
                // mt: 1,
                background: "#002E5C",
                "&:hover": {
                  background: "#002E5C",
                  color: "#fff",
                },
              }}
              variant="contained"
              size="small"
              width="150"
              height="80"
            >
              Next
            </Button>
{/* </a> */}



</Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
       
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background: "#fff",
                borderRadius: "5px",
                border: "1px solid #2A7BCC",
                p: 3,
                width: 300,
              }}
            >
              <BorderLinearProgress
                variant="determinate"
                value={80}
                sx={{ mb: 2 }}
              />
              <Typography sx={{ mb: 1.5 }}>
                Your payment is under process. Please contact your admission
                counsellor for further details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={handleClickChange}
                  variant="conatained"
                  size="small"
                  sx={{
                    textAlign: "center",
                    color: "#fff",

                    textTransform: "none",
                    background: "#2A7BCC",
                    "&:hover": { background: "#2A7BCC", color: "#fff" },
                  }}
                  // onClick={handleChangePayment}
                >
                  Choose Another Payment Method
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
    </Box>




      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
    </>
  );
};

export default AdmissionFee;
