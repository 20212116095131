import React, { useEffect, useState } from "react";
import { Box,Paper,Grid,CardMedia,Button } from "@mui/material";
// import CardMedia from "@mui/material";
import XLRI_LOGO from '../images/xlri.png'
import iit from '../images/EICTIITG.webp'
import logo from '../images/imi2.png'
import acc_logo from '../images/accredian-logo.png'
import AppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
const Navbar=()=>{
  const [user, setUser] = useState("");
  const navigate=useNavigate()
  const handleLogout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("email");
    localStorage.clear();
    navigate("/login");
  };
  useEffect(() => {
    var user_id = localStorage.getItem("user_id");
    setUser(user_id);
  }, []);
    return(
        <>
        {/* <AppBar
        
        position="static"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "0 0 2px 0 rgb(0 0 0 / 50%)",
          position: "fixed",
          zIndex: "99",
          height: "75px",
          display: { xs: "none", lg: "block" },
        }}
        > */}


        
        <Box sx={{background:"#fff",height:70,display: { xs: "none", lg: "block" }}}>
          <Box sx={{display:"flex"}}>
          <Box sx={{display:"flex",ml:6.8,width:"70%"}}>
              <Box sx={{p:1}}>
               <img src={logo} alt="logo" width={60} /> 
             
              </Box>
              
              
           
            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",ml:1}}>
            <hr style={{
                transform:"rotate(180deg)",
                 height: "50px",
               
                 border:"none",
                 background:"#000",
                 width:"1px",
               
                

            }} />
            </Box>
           <Box sx={{p:1,mt:2}}>
          
            <img src={acc_logo} alt="logo" width={180} /> 
           </Box>
          
            </Box>

            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",width:"30%"}}>
              {user?<Button onClick={handleLogout} variant="contained" size="small"  sx={{color:"#fff",background:"#000",height:"32px",ml:25,textTransform:"none",mt:-1,"&:hover":{color:"#fff",background:"#000"}}}>
              Logout
            </Button> :<></>}
           
            </Box>
            
          </Box>
           
        </Box>
        {/* </AppBar> */}


        {/* mobile version */}
        <Box sx={{background:"#fff",height:70,display: { xs: "block", lg: "none" }}}>
          <Box sx={{display:"flex"}}>
          <Box sx={{display:"flex",ml:1,width:"70%"}}>
              <Box sx={{p:1,pt:2}}>
               <img src={logo} alt="logo" width={40} /> 
             
              </Box>
              
              
           
            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",ml:1,pt:1}}>
            <hr style={{
                transform:"rotate(180deg)",
                 height: "35px",
               
                 border:"none",
                 background:"#000",
                 width:"1px",
               
                

            }} />
            </Box>
           <Box sx={{p:1,pt:2.5,mt:0.5}}>
          
            <img src={acc_logo} alt="logo" width={130} /> 
           </Box>
          
            </Box>
            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",width:"30%",pt:2}}>
            {user? <Button onClick={handleLogout} variant="contained" size="small"  sx={{color:"#fff",background:"#000",height:"25px",ml:4,textTransform:"none",mt:-1,"&:hover":{color:"#fff",background:"#000"}}}>
              Logout
            </Button> :<></>}
           
            </Box>
            
          </Box>
           
        </Box>
        </>
    )
}
export default Navbar