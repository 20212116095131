
import './App.css';
import Signup from './components/Signup';
import SignupNew from './components/signup-new';
import Login from './components/Login'
import LoginNew from './components/Login-new';
import SignupUpdated from './components/signup-updated'
import Basic from './components/form-steps/Basic';
import LoginUpdated from './components/Login-updated'
import BasicSales from './components/form-steps-Sales/Basic';
import {
  Routes,
  Route,
  Navigate,

} from "react-router-dom";
// import BasicDS from './components/form-steps-Sales/Basic';
function App() {
  return (
    <div className="App">
     <Routes>
    {/* <Route path='/' element={<Signup />} /> */}
    <Route path='/' element={<SignupUpdated />} />
    {/* <Route path='/login' element={<Login />} /> */}
    <Route path='/login' element={<LoginUpdated />} />
    <Route path='/form' element={<Basic />} />
    <Route path='/SMform' element={<BasicSales />} />
    {/* <div className='bg'> */}
    {/* <Route path='/Form' element={<Basic/>} /> */}
    {/* <Route path='/form' element={<PrivateOutlet/>} /> */}
    {/* <Route path='/dummy' element={<CustomizedInputs/>} /> */}
    {/* </div> */}
    
    </Routes>
    </div>
  );
}

export default App;
